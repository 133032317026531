@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #212121;
  color: #ffffff;
  font-family:cursive;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}

.jobs-header-wrapper
{
  margin-left: 50rem;
  margin: 10px;
  justify-content: center;
  align-items: center;
}

.select-menu
{
  justify-content: space-between;
}

.login-container {
  color: #000000;
  margin: 0;
}

.signup-container{
  background-image: url("/src/Assets/output-signup.png");
  background-size: cover;
  width: '200vw';
  height: '100vh';
  background-color: #000000;
}

.login-card {
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 50vh;
  max-height: 100vh;
  max-width: 50vh;
  margin-top: 100px;
  border-radius: 15px;
  background-image: url("/src/Assets/outputopacity.png");
  color: #ffffff;
}

.signup-card {
  justify-content: center;
  align-items: center;
  background-image: url("/src/Assets/output-signup.png");
  height: 100%;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 20px;
  /* Center and scale the image nicely */

  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
}

.signup-card h1{
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 40px;
opacity: 1;
  text-transform: uppercase;
font-weight: 900;
text-shadow: 0px 2px 6px black;
line-height: 1.1em;
-webkit-font-smoothing: antialiased;
margin-bottom: 15px;
color: #fe9e0d;
}

.login-card h1{
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 40px;
opacity: 1;
  text-transform: uppercase;
font-weight: 900;
text-shadow: 0px 2px 6px black;
line-height: 1.1em;
-webkit-font-smoothing: antialiased;
margin-bottom: 15px;
color: #fe9e0d;
}

.login-card input{
  justify-content: center;
  align-items: center;
  display: inline;
  font-size:18px;
  text-align: center;
margin-bottom: 15px;
font-weight: 400;
line-height: 1.1em;
-webkit-font-smoothing: antialiased;
color: #000000;
border-radius: 5px;
padding: 5px;
font-family: Verdana, Geneva, Tahoma, sans-serif;
font-weight: bold;
background-color: #ffffff;
}

.signup-card input{
  justify-content: center;
  align-items: center;
  display: inline;
  font-size:18px;
  text-align: center;
margin-bottom: 10px;
font-weight: 400;
line-height: 1.1em;
-webkit-font-smoothing: antialiased;
color: #000000;
border-radius: 5px;
padding: 5px;
font-family: Verdana, Geneva, Tahoma, sans-serif;
font-weight: bold;
background-color: #ffffff;
min-width: 250px;
}

.signup-card .loginicon{
  display: inline;
  margin-bottom: 7px;
  margin-left: 4px;
  font-size:18px;
  }

.login-card .loginicon{
display: inline;
margin-bottom: 7px;
margin-left: 4px;
}

.jobscard-container {
  margin-top: 20px;

  background-color: rgb(59, 59, 59);
  border-radius: 20px;
  color: #fffdfb;
}

.jobscard-container:hover {
  margin-top: 20px;
  justify-content: space-between;
  color: #ffffff;
  background-color: #5b5b5b;
  border-radius: 20px;  
}

.jobscard-content {
  padding: 5px;
  font-weight: 550; 
}

.jobscard-content p{
  margin-left: 130px;
  padding: 2px;
  font-weight:300; 
  font-size: 15px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.jobscard-content h1{
  margin-left: 125px;
  text-align: left;
  font-weight: bolder; 
  color: #fe9e0d;
  font-size: x-large;
}

.jobscard-container img{
height: 110px;
width: 110px;
margin-left: 1%;
float: left;
margin-top: 40px;
border-radius: 20px;}

.select-menu-container {
  width: 250px;
  margin-top: 10px;
  padding: 10px;
color: #fe9e0d;
font-size: 18px;
background-color: #000000;
border-radius: 20px;
}

.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 600;
  color: #ffffff;
}

.card{
  border: 1px solid hsl(hue, saturation, lightness);
  border-radius: 10px;
}

.normal-button {
  padding: 0.5rem 1rem;
  color: #fe9e0d;
  background-color: #000000;
  border-color: #000000;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
  margin: 15px;
}
.normal-button:hover {
  color: rgb(0, 0, 0);
    background-color: #fe9e0d;
  }

.primary-button {
  padding: 0.9rem 1.75rem;
  color: rgb(231, 129, 21);
  background-color: #000000;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.navbar-cart-icon {
  font-size: 1.15rem;
  color: #fe9e0d;
}
.primary-button:hover {
color: rgb(0, 0, 0);
  background-color: rgb(231, 129, 21);;
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -190px;
  z-index: -2;
  max-width: 700px;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.primary-heading {
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  color: #fe9e0d;
  /* line-height: 5rem; */
  max-width: 400px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #ffffff;
  margin: 1.5rem 0rem;
}
.secondary-button {
  padding: 0.8rem 2.5rem;

  background-color: #fe9e0d;
  color: #000000;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #000000;
  color: #fe9e0d;
  
}
.about-section-container {
  margin-top: 10rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: #fe9e0d;
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 0.5rem;
  display: flex;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.contact-page-wrapper h1 {
  max-width: 1000px !important;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}
.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}
.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}
.footer-wrapper {
  margin: 4rem 0rem;
  display: flex;
  margin-top: 1rem;
}
.footer-logo-container {
  max-width: 100px;
}
.footer-icons {
  margin-top: 2rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 0rem;
  color: #fe9e0d;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #fff5f5;
  cursor: pointer;
}
.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}
.testimonial-section-bottom {
  margin: 2rem auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.work-section-wrapper {
  margin-top: 10rem;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.work-section-info {
  width: 250px;
  min-height: 350px;
  background-color: rgb(59, 59, 59);
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #e7e4e4;
  margin: 1rem 2rem;
  
}
.work-section-info h2 {
  margin: 1rem 0rem;
}
.work-section-bottom {
  margin-top: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-family: 'Brush Script MT', cursive;
}
.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-family: 'Brush Script MT', cursive;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
    color: #ffffff;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
}
@media (max-width: 800px) {
  .nav-logo {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
    font-style: italic;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif Arial, Geneva, Helvetica, sans-serif;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.7rem 1.5rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 3rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 0rem !important;
    font-family: 'Brush Script MT', cursive;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 1rem;
  }
  .footer-section-columns {
    margin: 0rem 0rem;
  }
  .App {
    max-width: 95vw;
  }


}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
}